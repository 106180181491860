import React from 'react';
import { Link } from 'gatsby';

const ContactPage = () => (
  <div>
    <h1>Contact me</h1>
  </div>
);

export default ContactPage;
